import React from "react"
import { Parallax } from "react-scroll-parallax"
import styles from "./CornerContainer.module.css"

const CornerContainer = ({
  children,
  backgroundColor = "colorPurple",
  overlapSpacing = false,
  ignorePadding,
  ignoreParallax,
  style,
}) => {
  const content = (
    <div
      // ref={parallax}
      className={styles.container}
      style={{
        backgroundColor: `var(--${backgroundColor})`,
        marginTop: overlapSpacing ? "-180px" : 0,
        padding: ignorePadding && "0px",
        ...style,
      }}
    >
      <div className={styles.content}>{children}</div>
    </div>
  )

  if (ignoreParallax) {
    return content
  }
  return <Parallax speed={14}>{content}</Parallax>
}

export default CornerContainer
