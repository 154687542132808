import React from "react"
import { Link } from "gatsby"
import { ChevronRight, ChevronDown } from "react-feather"

import styles from "./header.module.css"
import classNames from "classnames/bind"
import { ArrowTopRightIcon } from "@radix-ui/react-icons"
const cx = classNames.bind(styles)

class Header extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isMenuOpen: false,
    }
  }

  toggleMenu = () => {
    this.setState(oldState => ({
      isMenuOpen: !oldState.isMenuOpen,
    }))
  }

  render() {
    if (this.props.minimal) {
      return (
        <>
          <div
            className={classNames(styles.container, {
              [styles.darkMode]: this.props.darkMode,
            })}
          >
            <Link to="/" className={styles.logoVisible}>
              u
              <div className={styles.logoSubtext}>
                <span className={styles.logoSubtext__text}>ndefined</span>
                <span className={styles.logoDot}>.</span>
              </div>
            </Link>
            <a
              className={styles.clutch}
              target="_blank"
              href="https://clutch.co/profile/undefined"
            >
              Clutch Review - 4.9 Stars
            </a>
          </div>
        </>
      )
    }

    return (
      <>
        {this.props.displayBanner && (
          <a
            href="https://calendly.com/undefined-siebe/discovery-call"
            target="_blank"
            className={styles.callBanner}
          >
            Got any questions or not sure what plan works best?{" "}
            <span>
              Book a call <ArrowTopRightIcon />
            </span>
          </a>
        )}
        <div
          className={classNames(styles.container, {
            [styles.darkMode]: this.props.darkMode,
          })}
        >
          <Link to="/" className={styles.logo}>
            u
            <div className={styles.logoSubtext}>
              <span className={styles.logoSubtext__text}>ndefined</span>
              <span className={styles.logoDot}>.</span>
            </div>
          </Link>
          <div className={styles.navContainer}>
            <div
              className={cx({
                navContainerLinks: true,
                isActive: this.state.isMenuOpen,
              })}
            >
              <ul className={styles.navList}>
                <li className={styles.navLink}>
                  <Link to="/work">Work</Link>
                </li>
                <li className={styles.navLink}>
                  <Link to="/services">Services</Link>

                  {/* <div className={styles.navDropdown}>
                  <div className={styles.arrowUp}></div>
                  <div className={styles.navDropdown__section}>
                    <a href="#" className={styles.navDropdown__primaryLink}>
                      <h3>
                        Product Development <ChevronRight />
                      </h3>
                      <p> 10x your development by adfaff lkfja lkdfj</p>
                    </a>
                    <div className={styles.navDropdown__secondaryLinks}>
                      <a href="#">
                        Progressive Prototyping <ChevronRight />
                      </a>
                      <a href="#">
                        App development <ChevronRight />
                      </a>
                    </div>
                    <div className={styles.navDropdown__secondaryLinks}>
                      <a href="#">
                        MVP Development <ChevronRight />
                      </a>
                      <a href="#">
                        Product Design & Makeover <ChevronRight />
                      </a>
                    </div>
                  </div>
                  <div className={styles.navDropdown__section}>
                    <a href="#" className={styles.navDropdown__primaryLink}>
                      <h3>
                        E-commerce & Shopify <ChevronRight />
                      </h3>
                      <p>
                        Description about progressive web apps that could cross
                        over both lines.
                      </p>
                    </a>
                    <a href="#" className={styles.navDropdown__primaryLink}>
                      <h3>
                        JAM Stack Websites <ChevronRight />
                      </h3>
                      <p>
                        Description about progressive web apps that could cross
                        over both lines.
                      </p>
                    </a>
                  </div>
                </div> */}
                </li>
                {/*
              <li className={styles.navLink}>
                <Link to="/blog">Pulse</Link>
              </li> */}
                <li className={styles.navLink}>
                  <Link to="/audit">UX Audit</Link>
                </li>
                <li className={styles.navLink}>
                  <Link to="/toolkit">
                    Toolkit <ChevronDown />
                  </Link>

                  <div className={styles.navDropdownMini}>
                    <div className={styles.arrowUp}></div>
                    <div className={styles.navDropdown__section}>
                      <Link
                        to="/toolkit/curated"
                        className={styles.navDropdown__primaryLink}
                      >
                        <p>
                          Curated Tools <ChevronRight />
                        </p>
                        <span>
                          A curated list of tools for product builders
                        </span>
                      </Link>
                    </div>
                    <div className={styles.navDropdown__section}>
                      <Link
                        to="/toolkit/product-lexicon"
                        className={styles.navDropdown__primaryLink}
                      >
                        <p>
                          Product Lexicon
                          <ChevronRight />
                        </p>
                        <span>
                          Our way of keeping you in the loop when it comes to
                          industry terms and chat.
                        </span>
                      </Link>
                    </div>
                    <div className={styles.navDropdown__section}>
                      <Link
                        to="/blog"
                        className={styles.navDropdown__primaryLink}
                      >
                        <p>
                          Our Insights
                          <ChevronRight />
                        </p>
                        <span>
                          Stories from the cutting edge of the digital world
                        </span>
                      </Link>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <Link to="/contact" className={styles.contactMenuBtn}>
              <span>{"GET IN TOUCH"}</span>
            </Link>
            <button
              className={cx({
                toggleMenu: true,
                menuActive: this.state.isMenuOpen,
              })}
              onClick={this.toggleMenu}
            >
              <span className={styles.toggleMenuLine}></span>
              <span className={styles.toggleMenuLine}></span>
              <span className={styles.toggleMenuLine}></span>
            </button>
          </div>
        </div>
      </>
    )
  }
}

export default Header
